import { Box, chakra, Flex, Image, Text, Button, useMediaQuery, Divider } from '@chakra-ui/react';
import { BackGroundTwo, ThreeForFreeBgMob, YogaJointLogoWithTextSmall } from 'assets';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '160px' },
    background: {
      base: 'linear-gradient(180deg, #181c43 0%, rgba(35, 41, 96, 0) 20%)',
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '120px', md: 'unset' },
    background: { base: `url(${ThreeForFreeBgMob})`, md: 'none' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
    width: { base: '100%', lg: '50%' },
  },
});

const RightContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '58px',
    width: { xl: '50%' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '33px', xl: '50px' },
    fontWeight: '500',
    lineHeight: { base: '40px', xl: '65px' },
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
    letterSpacing: '-1px',
    whiteSpace: 'pre-line',
    small: {
      fontSize: '0.3em',
      verticalAlign: 'super',
    },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '25px' },
    fontWeight: '500',
    lineHeight: { base: '23px', xl: '31px' },
    color: primaryTheme.colors.brand.pictonBlue,
    textAlign: { base: 'center', lg: 'unset' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '20px' },
    fontWeight: { base: '400', xl: '300' },
    lineHeight: { base: '26px', xl: '29px' },
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    width: '80px',
    border: '1.5px solid',
    opacity: '1',
    margin: { xl: '25px 0' },
  },
});

const HighLightText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '20px' },
    fontWeight: '700',
    lineHeight: { base: '22px', xl: '26px' },
    color: primaryTheme.colors.brand.lemonYellow,
    textAlign: { base: 'center', lg: 'unset' },
  },
});

type HeroSectionProps = {
  title: string;
  subTitle: string;
  description: string;
  image: string;
  buttonText?: string;
  highlightText?: string;
  bigText?: string;
  isRegistered?: boolean;
  onClick?: () => void;
};

const HeroSection = ({
  title,
  subTitle,
  description,
  image,
  buttonText,
  highlightText,
  bigText,
  isRegistered,
  onClick,
}: HeroSectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  return (
    <OuterContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
      <Container
        paddingTop={{ base: isRegistered ? '160px' : '29px', md: 'unset' }}
        paddingLeft={isMobileView ? margin : ''}
        paddingRight={isMobileView ? margin : ''}
      >
        {!isRegistered && isMobileView && (
          <Box margin="0 0 28px 0">
            <YogaJointLogoWithTextSmall />
          </Box>
        )}
        <LeftContainer>
          <Title
            animation={AnimationFrame.slideRight}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <SubTitle
            animation={AnimationFrame.slideLeft}
            dangerouslySetInnerHTML={{
              __html: subTitle,
            }}
          />
          {isMobileView && (
            <Image
              animation={AnimationFrame.fadeOut}
              src={image}
              objectFit="contain"
              maxWidth={{ base: isRegistered ? '80%' : '100%', md: '580px', xl: '580px' }}
              margin="auto"
              padding="20px 0"
              alignSelf={{ md: 'center', lg: 'unset' }}
            />
          )}
          {!isMobileView && <CustomDivider animation={AnimationFrame.slideRight} />}
          {!isRegistered && (
            <Description animation={AnimationFrame.slideLeft} fontWeight="700" fontSize="20px">
              {bigText}
            </Description>
          )}
          <Description
            animation={AnimationFrame.slideRight}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <HighLightText>{highlightText}</HighLightText>
          <Button
            variant="primary"
            size="primary"
            width={{ base: '267px', md: '316px' }}
            height="56px"
            _focus={{ outline: '0 !important' }}
            animation={AnimationFrame.slideLeft}
            margin={{ base: '39px auto 0', xl: '40px 0' }}
            fontWeight="700"
            fontSize="16px"
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </LeftContainer>
        {!isMobileView && (
          <RightContainer>
            <Image animation={AnimationFrame.fadeOut} src={image} objectFit="contain" maxWidth={{ md: '500px', xl: '670px' }} />
          </RightContainer>
        )}
      </Container>
    </OuterContainer>
  );
};

export default HeroSection;
