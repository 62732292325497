import { Box, chakra, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { BackgroundSeven } from 'assets';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { useRef } from 'react';

import { useInViewport } from 'react-in-viewport';
import FormFields from './FormFields';
import { LinkSection } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: `rgba(0, 0, 0, 0.2) url(${BackgroundSeven})`,
    backgroundPosition: 'right, right',
    backgroundSize: { base: 'cover', md: 'cover' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
    margin: { base: '80px 0 0 ', md: '221px auto 0' },
    gap: { lg: '40px', xl: '150px' },
    maxWidth: { xl: '1254px' },
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    gap: { base: '16px', lg: '80px' },
    width: { base: '100%', xl: '70%' },
    justifyContent: 'center',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '37px', xl: '50px' },
    fontWeight: '400',
    lineHeight: { base: '44px', xl: '60px' },
    color: primaryTheme.colors.brand.white,
    textTransform: 'capitalize',
    whiteSpace: 'pre-line',
    small: {
      fontSize: '0.3em',
      verticalAlign: 'super',
      marginLeft: '-2px',
    },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '19px', xl: '20px' },
    fontWeight: '500',
    lineHeight: { base: '25px', xl: '29px' },
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    maxWidth: { xl: '458px' },
  },
});

type FormSectionProps = {
  title: string;
  description: string;
  isRegistered?: boolean;
};

const CustomDivider = chakra(Box, {
  baseStyle: {
    height: { base: '260px' },
    borderLeft: { base: '1px solid' },
    borderStyle: 'dottsolided !important',
    color: primaryTheme.colors.brand.white,
    opacity: '35%',
    alignSelf: 'center',
  },
});

const FormSection = ({ title, description, isRegistered }: FormSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  const [isMobileViewWidth] = useMediaQuery('(max-width: 900px)');

  const { margin } = useNavBarDimensions();
  return (
    <OuterContainer
      opacity={enterCount >= 1 ? 1 : 0}
      ref={ref}
      paddingLeft={!isMobileView ? margin : '20px'}
      paddingRight={!isMobileView ? margin : '20px'}
    >
      <Container padding={{ base: '21px 0 10px', md: '68px 0' }}>
        <LeftContainer>
          <Title
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Description animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>{description}</Description>
        </LeftContainer>
        {isRegistered ? (
          <>
            {!isMobileViewWidth && <CustomDivider />}
            <LinkSection link="yogajoint.com/xxxx/nameofuser123" />
          </>
        ) : (
          <FormFields />
        )}
      </Container>
    </OuterContainer>
  );
};

export default FormSection;
