import { Box, chakra, Flex, Image, Text, useMediaQuery, Divider } from '@chakra-ui/react';
import { BackGroundTwo, PreSaleNextStepHero, ThreeForFreeBgMob } from 'assets';
import { PowerOfAppCard, TextContentColumn } from 'components';
import { primaryTheme } from 'theming';
import { AnimationFrame, Stub, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { lg: '160px' },
    background: {
      base: 'linear-gradient(180deg, #181c43 0%, rgba(35, 41, 96, 0) 20%)',
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '70px', lg: 'unset' },
    background: { base: `url(${ThreeForFreeBgMob})`, md: 'none' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
  },
});

const InnerContainer = chakra(Flex, {
  baseStyle: {
    gap: { base: '30px', lg: '200px' },
    padding: { base: '80px 0 0', lg: '80px 0 120px' },
    flexDirection: { base: 'column-reverse', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: { base: 'center', lg: 'unset' },
    gap: '20px',
    width: { base: '100%', lg: '50%' },
    paddingBottom: { base: '120px', lg: 'unset' },
  },
});

const RightContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '58px',
    width: { xl: '50%' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '24px', xl: '50px' },
    fontWeight: '500',
    lineHeight: { base: '21px', xl: '65px' },
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: '20px',
    fontWeight: '300',
    lineHeight: '29px',
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    width: '80px',
    border: '1.5px solid',
    opacity: '1',
    margin: { xl: '25px 0' },
  },
});

const CardsContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: { base: 'column', lg: 'row' },
    alignItems: 'flex-start',
    gap: { base: '30px', xl: '90px' },
    width: '100%',
    paddingTop: { base: '50px', lg: '90px' },
    justifyContent: { base: 'center', lg: 'unset' },
  },
});

const CustomDividerVertical = chakra(Box, {
  baseStyle: {
    height: { lg: '300px' },
    borderLeft: { lg: '2px solid' },
    borderTop: { base: '2px solid', lg: 'none' },
    width: { base: '85%', lg: '0' },
    borderStyle: 'dotted !important',
    color: '#4B5271',
    margin: 'auto',
  },
});

const HeroSection = () => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  return (
    <OuterContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
      <Container paddingLeft={isMobileView ? margin : ''} paddingRight={isMobileView ? margin : ''}>
        <TextContentColumn
          title="You don’t have to wait until Yoga Joints doors open to experience it!"
          description="Download our app now at NO COST!"
          isMainText
        />
        <InnerContainer>
          <LeftContainer>
            <Title animation={AnimationFrame.slideLeft}>Your Next Steps</Title>
            <CustomDivider />
            <Description animation={AnimationFrame.slideRight}>
              Get instant access to the Yoga Joint online suite of Flow, FIIT and Restore classes with our Yoga Joint app! Enjoy
              these classes while traveling or from the comfort of home, all at a time that’s most convenient for you (that’s
              anytime!).
            </Description>
          </LeftContainer>
          <RightContainer>
            <Image
              width={{ md: '60%', lg: 'unset' }}
              margin="auto"
              animation={AnimationFrame.fadeOut}
              src={PreSaleNextStepHero}
            />
          </RightContainer>
        </InnerContainer>
        <TextContentColumn
          title="Unleash the Power of Yoga Joint’s App"
          description="Dive Deeper into the FIIT & Flow Universe"
        />
        <CardsContainer>
          {Stub.PowerOfYjApp.map((item) => (
            <>
              <PowerOfAppCard
                key={item.id}
                title={item.title}
                description={item.description}
                image={item.image}
                color={item.color}
              />
              {item.id !== 3 && <CustomDividerVertical />}
            </>
          ))}
        </CardsContainer>
      </Container>
    </OuterContainer>
  );
};

export default HeroSection;
