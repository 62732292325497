import animationFrames from 'utils/animationFrames';
import { primaryTheme } from 'theming';
import { useEffect, useState, useCallback } from 'react';
import { chakra, Text, Flex, useMediaQuery } from '@chakra-ui/react';

const Container = chakra(Flex, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    top: 0,
    right: 0,
    left: 0,
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const PromoBarText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', lg: '22px' },
    fontWeight: '500',
    lineHeight: { base: '23px', md: '150%' },
    color: primaryTheme.colors.brand.white,
    letterSpacing: { base: '-1px', md: 'unset' },
    padding: { base: '0px 8px', md: '0 40px', lg: '0 20px' },
    maxWidth: { base: '360px', md: 'unset' },
    margin: { base: 'auto', md: 'unset' },
  },
});

type PromoBarProps = {
  isPromoClosed: boolean;
};

let timer: NodeJS.Timeout;
const PromoBar = ({ isPromoClosed }: PromoBarProps) => {
  const [showPromoBar, setPromoBarShow] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const setShowPromoBar = useCallback(
    (val: boolean) => {
      setPromoBarShow(val);
    },
    [setPromoBarShow]
  );

  useEffect(() => {
    const controlPromoVisibilty = () => {
      if (!isPromoClosed && (window.scrollY > lastScrollY + 30 || window.scrollY < lastScrollY - 30)) {
        const bottom = Math.ceil(window.innerHeight + Math.floor(window.scrollY)) >= document.documentElement.scrollHeight;
        if (window.scrollY <= 0) {
          setShowPromoBar(true);
        } else if (bottom) {
          setShowPromoBar(false);
        } else if (Math.floor(window.scrollY) === Math.floor(window.screenTop)) {
          setShowPromoBar(true);
        } else if (Math.floor(window.scrollY) > lastScrollY) {
          setShowPromoBar(false);
        } else if (Math.floor(window.scrollY) < lastScrollY) {
          setShowPromoBar(true);
        }

        timer = setTimeout(() => {
          setLastScrollY(Math.floor(window.scrollY));
        }, 300);
      }
    };

    window.addEventListener('scroll', controlPromoVisibilty);
    return () => {
      window.removeEventListener('scroll', controlPromoVisibilty);
      clearTimeout(timer);
    };
  }, [lastScrollY, isPromoClosed, setShowPromoBar, showPromoBar]);

  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  return (
    <Container
      position="fixed"
      overflow="hidden"
      transition="height 0.5s ease-in-out"
      height={{ base: showPromoBar ? '6rem' : '0px', lg: showPromoBar ? '77px' : '0px' }}
    >
      <Flex
        flexDir={isMobileView ? 'column' : undefined}
        animation={showPromoBar ? animationFrames.fadeInToPlaceAnimation : animationFrames.fadeOutOfPlaceAnimation}
        background={primaryTheme.colors.gradients.preSaleGradient}
        width="100%"
        padding={{ base: '11px 0', md: '18px 0' }}
        textAlign="center"
        justifyContent="center"
        cursor="pointer"
      >
        <PromoBarText>
          Congrats! You’re registered for a chance to win a{' '}
          <strong>$2,390 Louis Vuitton Yoga Mat FREE + $2,887 in Prizes!</strong>
        </PromoBarText>
      </Flex>
    </Container>
  );
};

export default PromoBar;
