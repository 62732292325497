import { primaryTheme } from 'theming';
import { chakra, Flex, Text, Image } from '@chakra-ui/react';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { AnimationFrame } from 'utils';

interface PowerOfAppCardProps {
  title: string;
  description: string;
  color: string;
  image: string;
}

const Container = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { base: '20px', lg: '40px' },
    width: '100%',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '20px', xl: '22px' },
    fontWeight: '600',
    lineHeight: { base: '29px', xl: '31px' },
    textAlign: 'center',
    maxWidth: { base: '200px', lg: '330px' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    fontSize: { base: '16px', lg: '20px' },
    fontWeight: '300',
    lineHeight: '150%',
    textAlign: 'center',
    maxWidth: { lg: '280px' },
  },
});

const PowerOfAppCard = ({ title, description, color, image }: PowerOfAppCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <Container opacity={enterCount >= 1 ? 1 : 0} ref={ref} animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
      <Image src={image} width="60px" />
      <Title color={color}>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default PowerOfAppCard;
