import { extendTheme, ThemeComponents } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { ButtonStyle as Button, TextStyle as Text, ModalStyle as Modal, ProgressStyle as Progress } from './customStyles';

const colors = {
  brand: {
    700: '#2a69ac',
    800: '#153e75',
    900: '#1a365d',
    red: '#FF0000',
    grey: '#808080',
    black: '#010101',
    haiti: '#262631',
    tiber: '#0C1627',
    white: 'white',
    cyprus: '#132A4D',
    violet: '#C081FC',
    yellow: '#ffd217',
    dimGray: '#676767',
    fuchsia: '#C544C5',
    salomie: '#FFE37A',
    tealBlue: '#307F9E',
    chambray: '#41567B',
    mayaBlue: '#8CB3FF',
    limeGreen: '#41BC3F',
    slateGrey: '#747E8E',
    pictonBlue: '#3CBCEB',
    heliotrope: '#BE4BFF',
    mulledWine: '#4C4859',
    paleViolet: '#DEA4FF',
    bitterSweet: '#FB5E5E',
    blueSpindle: '#B7C6DC',
    lemonYellow: '#FFD215',
    placeholder: '#838E9E',
    vividViolet: '#7A2D82',
    CeSoirViolet: '#A158A6',
    blackRussian: '#24262c',
    blackcurrant: '#380F3A',
    blueSpindle1: '#B0BFD7',
    chathamsBlue: '#29446B',
    mediumOrchid: '#E46AEA',
    midnightBlue: '#0D1942',
    blackAlpha: 'blackAlpha',
    cornFlowerBlue: '#89DFFF',
    cornflowerBlue: '#4F73EF',
    lightSlateBlue: '#B28AFF',
    lightSlateGrey: '#828D9D',
    midnightExpress: '#242E3E',
    blackestBerry: '#622062',
    darkTeal: '#1B576E',
    amethyst: '#B04CEE',
    darkViolet: '#35216A',
    yellowHover: '#E1B600',
    highlightYellow: '#F8D34C',
    mediumSlateBlue: '#7271F9',
    violetPurple: '#7F63F1',
    blueGrey: '#9CA1C5',
  },
  button: {
    primary: '#FFD215',
    secondary: '#FFD31A',
    tertiary: '#00000',
  },
  changedOpacityColors: {
    white_pointTwo: 'rgba(255, 255, 255, 0.2)',
    white_pointTwoFive: 'rgba(255, 255, 255, 0.25)',
    white_pointTwoSix: 'rgba(255, 255, 255, 0.26)',
    white_pointThree: 'rgba(255,255,255,0.3)',
    white_pointFour: 'rgba(255, 255, 255, 0.4)',
    white_pointFive: 'rgba(255, 255, 255, 0.5)',
    black_pointSevenSeven: 'rgba(1, 1, 1, 0.77)',
    blackcurrant_pointSixFive: 'rgba(56, 15, 58, 0.65)',
    midnightBlue_pointSixFive: 'rgba(13, 25, 66, 0.65)',
    black_pointSixFive: 'rgba(1, 1, 1, 0.65)',
    black_pointNine: 'rgba(1, 1, 1, 0.9)',
  },
  gradients: {
    openings: 'linear-gradient(85.35deg, rgba(54, 82, 184, 1) 4.51%, rgba(95, 40, 153, 1) 98.26%)',
    faqBg: 'linear-gradient(85.35deg, #4D74FF 4.51%, #7D37C9 98.26%)',
    locationSegmentBg: 'linear-gradient(90deg, rgba(84, 122, 255, 1) 0%, rgba(255, 66, 219, 1) 60%)',
    locationText: 'linear-gradient(to-l, #547AFF, #FF42DB )',
    locationImage:
      '-webkit-gradient(linear,  left top, left bottom, color-stop(0%,transparent), color-stop(50%,transparent), color-stop(50%,#FFF), color-stop(100%,#FFF), color-stop(50%,#FFF), color-stop(100%,#FFF))',
    reviewCardBg: 'linear-gradient(180deg, #0C1627 0%, rgba(12, 22, 39, 0) 100%)',
    reviewCardLocationText: 'linear-gradient(271.55deg, #547AFF -181.83%, #FF42DB 100.97%), #000000;',
    pricingMembership: 'linear-gradient(180deg, #220923 0%, rgba(34, 9, 35, 0) 100%)',
    midtownPricingMembership: 'linear-gradient(180deg, #081C24 0%, #000000 100%)',
    testimonialsHero: 'linear-gradient(180deg, #000D3A 0%, rgba(0, 13, 58, 0) 100%)',
    classesHero: 'linear-gradient(180deg, #010101 0%, rgba(1, 1, 1, 0) 100%)',
    upcomingLocationsHeading: 'linear-gradient(271.55deg, #547AFF 20.83%, #FF42DB 100.97%), #000000;',
    customVideoGradient: 'linear-gradient(180deg, #010101 0%, rgba(1, 1, 1, 0) 100%)',
    preSaleGradient: 'linear-gradient(274.64deg, #547AFF 11.11%, #FF42DB 116.92%)',
  },
  backdropFilters: {
    reviewCard: 'blur(34px)',
  },
  darkText: {
    primary: '#0C1627',
  },
  icon: {
    primary: '#3CBCEB',
  },
};

const components: ThemeComponents = {
  Text,
  Modal,
  Button,
  Progress,
};

const fonts = {
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
  },
};

const breakpoints = createBreakpoints({
  xs: '0px',
  sm: '320px',
  smA: '480px',
  md: '768px',
  mdA: '820px',
  mdB: '912px',
  lg: '960px',
  lgA: '1024px',
  lgB: '1180px',
  xl: '1200px',
  xlA: '1366',
  '1xl': '1400px',
  '2xl': '1500px',
  '3xl': '1600px',
  '3xlA': '1800px',
  '3xlB': '2000px',
  '4xl': '2560px',
});

const primaryTheme = extendTheme({ colors, components, fonts, breakpoints });

export default primaryTheme;
