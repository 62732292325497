import { ComponentStyleConfig } from '@chakra-ui/react';

const ProgressStyle: ComponentStyleConfig = {
  baseStyle: {
    track: {
      bg: '#7A2D82',
    },
    filledTrack: {
      bg: '#FFD215',
    },
  },
};

export default ProgressStyle;
