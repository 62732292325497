import { Box, chakra, Flex, Text, useMediaQuery, Divider } from '@chakra-ui/react';
import { BackGroundTwo } from 'assets';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '160px' },
    background: `url(${BackGroundTwo})`,
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
    paddingBottom: { lg: '125px' },
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '42px', md: 'unset' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '30px', xl: '51px' },
    fontWeight: '500',
    lineHeight: { base: '40px', xl: '67px' },
    textAlign: 'center',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    whiteSpace: 'pre-line',
    maxWidth: { xl: '877px' },
    margin: 'auto',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '24px', xl: '30px' },
    fontWeight: '300',
    lineHeight: { base: '36px', xl: '51px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: { xl: '637px' },
    margin: 'auto',
    textAlign: 'center',
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    width: '80px',
    border: '1.5px solid',
    opacity: '1',
    margin: { base: '50px 0', xl: '25px 0' },
  },
});

type GetReadySectionProps = {
  title: string;
  description: string;
};

const GetReadySection = ({ title, description }: GetReadySectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer
      ref={ref}
      opacity={enterCount >= 1 ? 1 : 0}
      paddingLeft={!isMobileView ? margin : ''}
      paddingRight={!isMobileView ? margin : ''}
    >
      <Container paddingLeft={isMobileView ? margin : ''} paddingRight={isMobileView ? margin : ''}>
        <Title
          animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <CustomDivider />
        <Description
          animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Container>
    </OuterContainer>
  );
};

export default GetReadySection;
