import { Box, chakra, Flex, Grid, Text } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';

import { useInViewport } from 'react-in-viewport';
import { SinglePackage } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    padding: { base: '107px 0 0', lg: '157px 0 0' },
    maxWidth: { xl: '1254px' },
    margin: { xl: 'auto' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '35px', xl: '48px' },
    fontWeight: '500',
    lineHeight: { base: '42px', xl: '62px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: { base: '60%', md: 'unset' },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: '20px', xl: '24px' },
    fontWeight: '500',
    lineHeight: { base: '26px', xl: '31px' },
    margin: { md: '24px 0' },
    backgroundClip: 'text',
    width: '267px',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', lg: '20px' },
    fontWeight: { base: '400', lg: '300' },
    lineHeight: { base: '25px', lg: '29px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: '487px',
    margin: { base: '20px 0 ', lg: '0 0 30px 0' },
  },
});

type PackageCard = {
  index: string;
  image: string;
  title: string;
  price: string;
};

type PackagesProps = {
  title: string;
  price: string;
  description: string;
  card: PackageCard[];
  background: string;
  color: string;
};

const Packages = ({ title, price, description, card, background, color }: PackagesProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <OuterContainer opacity={enterCount >= 1 ? 1 : 0} ref={ref}>
      <Flex flexDirection={{ base: 'row', md: 'column' }} alignItems={{ base: 'flex-end', md: 'unset' }}>
        <Title animation={inViewport || enterCount ? AnimationFrame.slideDown : 'none'}>{title}</Title>
        <SubTitle animation={inViewport || enterCount ? AnimationFrame.slideDown : 'none'}>{price}</SubTitle>
      </Flex>
      <Description animation={inViewport || enterCount ? AnimationFrame.slideDown : 'none'}>{description}</Description>
      <Grid
        templateRows={{ base: 'repeat(1fr)' }}
        templateColumns={{ base: 'repeat(1fr)', lg: 'repeat(3, 1fr)' }}
        gap={{ base: '12px', xl: '37px' }}
      >
        {card.map((singleCard) => {
          return (
            <SinglePackage
              key={singleCard.index}
              title={singleCard.title}
              price={singleCard.price}
              image={singleCard.image}
              background={background}
              color={color}
            />
          );
        })}
      </Grid>
    </OuterContainer>
  );
};

export default Packages;
