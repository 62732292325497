import * as yup from 'yup';

export const EnterFreeGiveAway = yup.object({
  email: yup.string().default('').email('Enter a valid email').required('Email is required'),
  FirstName: yup.string().default('').required('Last Name is required').min(2, 'Name is too short').max(50, 'Name is too long'),
  LastName: yup.string().default('').required('First Name is required').min(2, 'Name is too short').max(50, 'Name is too long'),
  Phone: yup
    .string()
    .default('')
    .required('Telephone Number is required')
    .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Telephone Number is invalid'),
});

const yupSchemas = {
  EnterFreeGiveAway,
};

export default yupSchemas;
