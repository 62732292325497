import useWindowSize from './useWindowSize';

const useNavBarDimensions = (change = 0) => {
  const windowSize = useWindowSize();

  return {
    margin: {
      base: `${windowSize[0] * 0.0725 + change}px`,
      smA: `${windowSize[0] * 0.07675 + change}px`,
      md: `${windowSize[0] * 0.08 + change}px`,
      lg: `${windowSize[0] * 0.09 + change}px`,
      xl: `${windowSize[0] * 0.113 + change}px`,
    },
  };
};

export default useNavBarDimensions;
