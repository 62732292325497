import { Box, BoxProps } from '@chakra-ui/react';

const PlayStoreIcon = (props: BoxProps) => {
  return (
    <Box as="svg" width="32px" height="35px" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.34575 0.473741C1.05724 0.779102 0.886719 1.253 0.886719 1.8667V23.7931C0.886719 24.4078 1.05724 24.8807 1.34575 25.1861L1.41912 25.2575L13.7019 12.9747V12.8299V12.6852L1.41912 0.401367L1.34575 0.473741Z"
        fill="url(#paint0_linear_1961_8396)"
      />
      <path
        d="M17.7928 17.071L13.6992 12.9754V12.8307V12.6859L17.7938 8.59131L17.886 8.64385L22.7371 11.4C24.1231 12.1872 24.1231 13.4751 22.7371 14.2633L17.886 17.0194L17.7928 17.071Z"
        fill="url(#paint1_linear_1961_8396)"
      />
      <path
        d="M17.8916 17.0179L13.7048 12.8301L1.34766 25.1863C1.8047 25.6701 2.55819 25.7296 3.40784 25.2467L17.8916 17.0179Z"
        fill="url(#paint2_linear_1961_8396)"
      />
      <path
        d="M17.8906 8.64175L3.40785 0.412887C2.55819 -0.0699385 1.8047 -0.00946145 1.34766 0.473364L13.7038 12.8295L17.8906 8.64175Z"
        fill="url(#paint3_linear_1961_8396)"
      />
      <path
        opacity="0.2"
        d="M17.7994 16.9248L3.40884 25.1011C2.6038 25.5582 1.88402 25.5284 1.42201 25.112L1.34766 25.1864L1.42102 25.2578C1.88303 25.6742 2.6028 25.7049 3.40784 25.2469L17.8906 17.018L17.7994 16.9248Z"
        fill="black"
      />
      <path
        opacity="0.12"
        d="M1.34575 25.0424C1.05724 24.737 0.886719 24.2631 0.886719 23.6494V23.7942C0.886719 24.4079 1.05724 24.8818 1.34575 25.1871L1.42011 25.1128L1.34575 25.0424Z"
        fill="black"
      />
      <path
        opacity="0.12"
        d="M22.744 14.1174L17.8008 16.9261L17.893 17.0183L22.744 14.2622C23.4371 13.8686 23.7831 13.3501 23.7831 12.8315C23.7246 13.3005 23.3716 13.7605 22.744 14.1174Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M3.40594 0.558123L22.7398 11.5432C23.3684 11.9001 23.7203 12.3601 23.7788 12.83C23.7788 12.3115 23.4328 11.792 22.7398 11.3984L3.40594 0.413374C2.01992 -0.37382 0.886719 0.279531 0.886719 1.86681V2.01255C0.886719 0.425271 2.02091 -0.229072 3.40594 0.558123Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1961_8396"
          x1="12.6129"
          y1="1.6355"
          x2="-4.02561"
          y2="18.2741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.0066" stopColor="#00A1FF" />
          <stop offset="0.2601" stopColor="#00BEFF" />
          <stop offset="0.5122" stopColor="#00D2FF" />
          <stop offset="0.7604" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1961_8396"
          x1="24.5408"
          y1="12.8302"
          x2="0.551286"
          y2="12.8302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.4087" stopColor="#FFBD00" />
          <stop offset="0.7754" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1961_8396"
          x1="15.6162"
          y1="15.1056"
          x2="-6.94704"
          y2="37.6688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1961_8396"
          x1="-1.76424"
          y1="-6.82524"
          x2="8.31125"
          y2="3.25016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.0685" stopColor="#2DA771" />
          <stop offset="0.4762" stopColor="#15CF74" />
          <stop offset="0.8009" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </Box>
  );
};

export default PlayStoreIcon;
