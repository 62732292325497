import { OptionsRouter } from 'react-typesafe-routes';
import { HomePage, ReferralPage, ThankYouPage } from 'pages';

const defaultOptions = {
  isHomePage: false,
  isMailer: false,
  isAd: false,
};

const router = OptionsRouter(defaultOptions, (route) => ({
  home: route('/', {
    component: HomePage,
    options: {
      isHomePage: true,
    },
  }),
  mailer: route('mailer', {
    component: HomePage,
    options: {
      isHomePage: true,
      isMailer: true,
    },
  }),
  online: route('online', {
    component: HomePage,
    options: {
      isHomePage: true,
      isAd: true,
    },
  }),
  referral: route('referral', {
    component: ReferralPage,
  }),
  referralMailer: route('mailer/referral', {
    component: ReferralPage,
    options: {
      isMailer: true,
    },
  }),
  referralAd: route('online/referral', {
    component: ReferralPage,
    options: {
      isAd: true,
    },
  }),
  PreSaleNextStep: route('thankyou', {
    component: ThankYouPage,
  }),
}));

export default router;
