import { Box, chakra, Flex, Image, Text, Button, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo, WhatIsYogaJoint } from 'assets';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '160px' },
    background: {
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    paddingTop: { base: '70px', md: 'unset' },
    gap: { lg: '100px' },
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
    width: { base: '100%', lg: '40%' },
  },
});

const RightContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '58px',
    width: { xl: '50%' },
    paddingTop: '20px',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '32px', xl: '45px' },
    fontWeight: '500',
    lineHeight: { base: '36px', xl: '58px' },
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
    marginBottom: { base: '20px', lg: 'unset' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '20px' },
    fontWeight: { base: '400', xl: '300' },
    lineHeight: { base: '26px', xl: '29px' },
    color: primaryTheme.colors.brand.white,
    marginBottom: '30px',
    textAlign: { base: 'center', lg: 'unset' },
  },
});

type WhatIsYogaJointSectionProps = {
  onClick: () => void;
};

const WhatIsYogaJointSection = ({ onClick }: WhatIsYogaJointSectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
      <Container
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
        paddingLeft={isMobileView ? margin : ''}
        paddingRight={isMobileView ? margin : ''}
      >
        <LeftContainer>
          {isMobileView && (
            <Title animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>What Is Yoga Joint?</Title>
          )}
          <Image
            animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
            src={WhatIsYogaJoint}
            maxHeight={{ md: '712px' }}
            maxWidth={{ base: '60%', lg: '712px' }}
            alignSelf={{ base: 'center', lg: 'unset' }}
          />
        </LeftContainer>
        <RightContainer>
          {!isMobileView && (
            <Title animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>
              What Is <br />
              Yoga Joint?
            </Title>
          )}
          <Flex flexDirection="column">
            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}>
              <strong>Yoga Joint</strong> is pioneering a revolutionary fitness methodology that combines the stretching and
              flexibility of traditional yoga with the strength, conditioning, and fat loss benefits of resistance training.
            </Description>

            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>
              The result is a safe, efficient, and effective total body workout that torches fat and sculpts muscle.
            </Description>
            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}>
              Combined with our infrared studio classes, Yoga Joint FIIT and Flow classes strengthen your mind as much as your
              body.
            </Description>
            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>
              Whether you’re a yoga enthusiast looking to get more out of your yoga practice, an athlete who wants to prevent
              injury and develop a mind as resilient as your muscles, or you just want to look better, feel better, and process
              life in a healthy way, Yoga Joint FIIT and Flow classes get the job done!
            </Description>
            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>
              <strong>
                So type in your email address below to win one of our EPIC Grand Opening Celebration Giveaway prize packages!
              </strong>
            </Description>
            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}>
              Then come by when we <strong>open in Coral Springs in the spring</strong>, say hi, and try a Yoga Joint class of
              your choice completely free!
            </Description>
            <Button
              variant="primary"
              size="primary"
              width={{ base: '100%', md: '316px' }}
              height="56px"
              _focus={{ outline: '0 !important' }}
              animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}
              onClick={onClick}
              margin={{ base: '39px auto 0', xl: '40px 0' }}
              alignSelf={{ base: 'center', lg: 'unset' }}
              fontSize="16px"
              fontWeight="700"
            >
              ENTER FREE GIVEAWAY NOW!
            </Button>
          </Flex>
        </RightContainer>
      </Container>
    </OuterContainer>
  );
};

export default WhatIsYogaJointSection;
