import { Button, ButtonProps, chakra } from '@chakra-ui/react';
import { primaryTheme } from 'theming';

interface RequestButtonProps extends ButtonProps {
  title: string;
  isQuestionSection?: boolean;
  isForm?: boolean;
  width?: string;
  onClick?: any;
}

const ButtonContainer = chakra(Button, {
  baseStyle: {
    height: '48px',
    borderRadius: '168px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: primaryTheme.colors.brand.cyprus,
    background: primaryTheme.colors.button.primary,
    border: `2px solid ${primaryTheme.colors.brand.salomie}`,
    boxShadow: 'inset 0px 0px 12px rgba(255, 255, 255, 0.4)',
    _hover: {
      cursor: 'pointer',
      background: primaryTheme.colors.brand.yellowHover,
    },
  },
});

const RequestButton = ({
  title,
  isQuestionSection = false,
  isForm = false,
  width = '239px',
  onClick,
  type,
  marginTop,
}: RequestButtonProps) => {
  const getMargin = () => {
    if (isForm) return '40px';
    return width === '296px' ? '0px' : '45px';
  };
  return (
    <ButtonContainer
      width={isForm ? '100%' : { base: isQuestionSection ? '95%' : width, md: width }}
      marginTop={marginTop ?? { base: getMargin(), lg: isForm ? '18px' : '45px' }}
      boxShadow={
        isForm || width === '296px'
          ? 'inset 0px 0px 12px rgba(255, 255, 255, 0.4)'
          : '0px 0px 24px rgba(255, 239, 169, 0.5), inset 0px 0px 12px rgba(255, 255, 255, 0.4)'
      }
      onClick={onClick}
      type={type}
    >
      {title}
    </ButtonContainer>
  );
};

export default RequestButton;
