import { Box, BoxProps } from '@chakra-ui/react';

const AppleIcon = (props: BoxProps) => {
  return (
    <Box as="svg" width="24px" height="30px" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0689 0C13.1649 1.2534 12.7685 2.49484 11.9639 3.4607C11.5787 3.93989 11.0897 4.3254 10.5338 4.58822C9.97796 4.85103 9.36971 4.9843 8.75489 4.97796C8.71567 4.37522 8.79562 3.77065 8.99018 3.19883C9.18475 2.627 9.4901 2.09913 9.8888 1.64539C10.7042 0.716585 11.8397 0.129104 13.0689 0ZM15.3094 9.1225C14.9009 9.84224 14.6811 10.6536 14.6705 11.4811C14.6715 12.4119 14.9465 13.3219 15.4611 14.0975C15.9758 14.873 16.7074 15.48 17.5647 15.8426C17.2276 16.9375 16.7196 17.9723 16.0593 18.9086C15.1726 20.235 14.2429 21.5309 12.7672 21.5548C12.0655 21.571 11.5917 21.3692 11.0981 21.1589C10.5832 20.9396 10.0466 20.711 9.20703 20.711C8.31658 20.711 7.756 20.947 7.21535 21.1745C6.74813 21.3711 6.29579 21.5615 5.65831 21.5879C4.253 21.64 3.17901 20.1722 2.26005 18.8582C0.423322 16.1748 -1.0069 11.2959 0.910521 7.97623C1.35316 7.18081 1.9943 6.51362 2.77145 6.03966C3.54861 5.5657 4.43529 5.30113 5.3451 5.27174C6.14213 5.25534 6.90694 5.56254 7.57746 5.83187C8.09026 6.03784 8.54791 6.22167 8.92268 6.22167C9.25211 6.22167 9.69699 6.0451 10.2155 5.83932C11.0322 5.51518 12.0315 5.11856 13.0499 5.22545C13.8304 5.24987 14.5944 5.45593 15.2813 5.82727C15.9681 6.19861 16.559 6.725 17.0068 7.36464C16.3018 7.79806 15.7179 8.40277 15.3094 9.1225Z"
        fill="white"
      />
    </Box>
  );
};

export default AppleIcon;
