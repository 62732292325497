import { ComponentStyleConfig } from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';

const ButtonStyle: ComponentStyleConfig = {
  sizes: {
    primary: {
      width: 233,
      height: 'max-content',
      px: 2,
      py: 3,
    },
    secondary: {
      width: { base: 42, sm: 107, md: 130, lg: 181 },
      height: { base: '32px', md: 'max-content' },
      px: { base: '0px', md: '35px' },
      py: { base: 2, sm: '9px 18px', md: '19px' },
    },
    tertiary: {
      width: { base: 233, md: 182, mdA: 185, mdB: 205, '1xl': 210 },
      height: 'max-content',
      px: 2,
      py: 4,
    },
    modal: {
      width: { base: '90%', md: '228px' },
      height: '47px',
      py: 3,
      px: 4,
    },
    100: {
      width: '100%',
      px: 2,
      py: 3,
    },
    90: {
      width: '90%',
      px: 2,
      py: 3,
    },
    80: {
      width: '80%',
      px: 2,
      py: 3,
    },
    70: {
      width: '70%',
      px: 2,
      py: 3,
    },
  },
  variants: {
    primary: {
      bg: 'button.primary',
      border: '2px solid #FFE37A',
      borderRadius: '163px',
      color: '#132a4d',
      fontSize: '14px',
      boxShadow: '0px 0px 24px rgba(255, 239, 169, 0.5), inset 0px 0px 12px rgba(255, 255, 255, 0.4)',
      textTransform: 'uppercase',
      _hover: {
        bg: darken('button.primary', 10),
        border: `2px solid ${darken('button.secondary', 10)}`,
        cursor: 'pointer',
      },
    },
    // Booking Variant
    secondary: {
      bg: 'button.secondary',
      borderRadius: { base: '7px', md: '12px' },
      color: 'black',
      fontFamily: 'Montserrat',
      fontSize: { base: 8, sm: 10, md: 11, lg: 16 },
      textTransform: 'uppercase',
      fontWeight: 'bold',
      lineHeight: '98%',
      boxShadow: '0px 0px 16px rgba(255, 211, 26, 0.5)',
      _hover: {
        bg: darken('button.secondary', 5),
        cursor: 'pointer',
      },
    },
    // SignIn Variant
    tertiary: {
      border: '1px solid #FFFFFF',
      bg: 'transparent',
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      borderRadius: 0,
      _hover: {
        transform: 'scale(1.02)',
        cursor: 'pointer',
      },
    },

    // Download App variant
    quaternary: {
      border: '0.991429px solid #FFFFFF',
      color: '#FFFFFF',
      boxSizing: 'border-box',
      borderRadius: '4.95714px',
      bg: '#00000',
      textTransform: 'uppercase',
      fontWeight: 700,
      fontFamily: 'Montserrat',
      fontSize: '14px',
      _hover: {
        boxShadow: darken('button.primary', 10),
      },
    },
    // footer variant
    footerButton: {
      bg: 'transparent',
      padding: 0,
      color: '#747E8E',
      fontSize: 12,
      textTransform: 'capitalize',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      _hover: {
        cursor: 'pointer',
      },
    },

    disabled: {
      bg: 'button.primary',
      border: '2px solid #FFE37A',
      borderRadius: '163px',
      color: '#132a4d',
      fontFamily: 'Montserrat',
      boxShadow: '0px 0px 24px rgba(255, 239, 169, 0.5), inset 0px 0px 12px rgba(255, 255, 255, 0.4)',
      textTransform: 'capitalize',
      _hover: 'none',
    },
    textButton: {
      bg: 'transparent',
      color: 'button.primary',
    },
    modal: {
      width: { base: '100%', smA: '228px' },
      height: '47px',
      fontSize: '12.8889px',
      fontWeight: '700',
      color: '#132A4D',
      padding: '17px 34px',
      background: 'button.primary',
      border: '1.75908px solid #FFE37A',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '147.763px',
    },

    // formButtom
    formButton: {
      width: { base: '233px', md: '297px' },
      height: { base: '48px' },
      borderRadius: '168px',
      boxSizing: 'border-box',
      color: 'black',
      background: 'button.primary',
      border: '2px solid button.secondary',
      boxShadow: '0px 0px 24px rgba(255, 239, 169, 0.5), inset 0px 0px 12px rgba(255, 255, 255, 0.4)',
      _hover: {
        background: darken('button.primary', 10),
        border: darken('2px solid button.secondary', 10),
      },
      _disabled: {
        _hover: {
          background: 'button.primary',
          border: '2px solid button.secondary',
        },
      },
    },
  },
};

export default ButtonStyle;
