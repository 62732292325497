import {
  IconMuscle,
  IconStrength,
  IconEndurance,
  IconHeart,
  IconFlexibility,
  IconRespiratory,
  IconMotion,
  IconInjury,
  YogaJointMethod1,
  YogaJointMethod2,
  YogaJointMethod3,
  YogaJointMethod4,
  YogaJointMethod5,
  PackageIcon11,
  PackageIcon12,
  PackageIcon14,
  PackageIcon13,
  PackageIcon15,
  PackageIcon16,
  PackageIcon21,
  PackageIcon22,
  PackageIcon23,
  PackageIcon24,
  PackageIcon25,
  PackageIcon31,
  PackageIcon32,
  PackageIcon33,
  PlayScreenIcon,
  ScheduleIcon,
  BellIcon,
} from './images';
// import {
//   UpcomingBenefitsIcon1,
//   UpcomingBenefitsIcon2,
//   UpcomingBenefitsIcon3,
//   UpcomingBenefitsIcon4,
//   UpcomingBenefitsIcon5,
// } from 'assets';
import { primaryTheme } from 'theming';

const yogaBenefits1 = [
  {
    index: 0,
    image: IconMuscle,
    title: 'Build Muscle',
  },
  {
    index: 1,
    image: IconStrength,
    title: 'Give You Strength',
  },
  {
    index: 2,
    image: IconEndurance,
    title: 'Increase Your Endurance',
  },
  {
    index: 3,
    image: IconHeart,
    title: 'Strengthen Your Heart',
  },
];

const yogaBenefits2 = [
  {
    index: 0,
    image: IconFlexibility,
    title: 'Increased Flexibility',
  },
  {
    index: 1,
    image: IconRespiratory,
    title: 'Respiratory Control',
  },
  {
    index: 2,
    image: IconMotion,
    title: 'Range of Motion',
  },
  {
    index: 3,
    image: IconInjury,
    title: 'A Reduced Chance of Injury',
  },
];
const YogaJointMethod = [
  {
    id: 1,
    title: 'world-class studios',
    description:
      'Our world-class studios have been designed with the utmost care and attention to detail. Featuring a state-of-the-art sound system, infrared heat, and impact-absorbing floors, our studios will immerse you in an unforgettable class experience.',
    image: YogaJointMethod1,
    isReverse: true,
  },
  {
    id: 2,
    title: 'energy-efficient infrared heating systems',
    description:
      'Our infrared heating systems transfer sun-like warmth to your body, igniting your core from the inside out. Experience all the benefits of infrared heat, such as reduced recovery time, increased flexibility, improved cardiovascular health, and relaxation.',
    image: YogaJointMethod2,
  },
  {
    id: 3,
    title: 'Custom-Designed Playlists',
    description:
      'Our custom playlists get your heart pumping. Prepare for a major shift with our high-energy music and empowering teachers as they help you push past your limits.',
    image: YogaJointMethod3,
    isReverse: true,
  },
  {
    id: 4,
    title: 'Best-In-Class TEACHERS',
    description:
      'Our teachers are trained for months on the Yoga Joint Method before teaching their first class. They are quite literally as highly-trained as they come and understand how to COMBINE both the strength-training and cardio of traditional HIIT programs with yoga flows to increase your flexibility, improve breathwork, and maximize your workout!',
    image: YogaJointMethod4,
  },
  {
    id: 5,
    title: 'Reserve Your Spot',
    description:
      'Reserve your spot ahead of time. If you have ever been to a studio or fitness class, you know the frustration of having to arrive super early just to get the spot you want. With our world-class app, you can reserve your favorite spot and never worry about that again.',
    image: YogaJointMethod5,
    isReverse: true,
  },
];

const YogaJointPeople = [
  {
    id: 1,
    title: 'People who focus on strength, cardio and conditioning,',
    description:
      'love supplementing their workouts with Yoga Joint because it helps them improve their range of motion, flexibility, prevent injuries and take their strength routine to entirely new levels.',
    color: primaryTheme.colors.brand.amethyst,
    background: 'linear-gradient(180deg, #350E3B 0%, rgba(28, 8, 30, 0) 100%)',
  },
  {
    id: 2,
    title: 'People who focus primarily on yoga or Pilates',
    description:
      'love Yoga Joint because it helps them to quickly develop new levels of strength and conditioning and achieve poses and postures that would take forever if just doing yoga alone.',
    color: primaryTheme.colors.brand.mediumSlateBlue,
    background: 'linear-gradient(180deg, #091331 0%, rgba(7, 13, 34, 0) 100%)',
  },
  {
    id: 3,
    title: 'People who are looking to have an all-in-one routine',
    description:
      'that will help them become stronger, fitter, more flexible and less injury-prone in the shortest amount of time possible.',
    color: primaryTheme.colors.brand.violetPurple,
    background: 'linear-gradient(180deg, #280D4B 0%, rgba(28, 8, 30, 0) 100%)',
  },
];

const PreSalePackages = [
  {
    index: 1,
    title: 'Diamond Grand Prize Package',
    price: '$5,277 VALUE',
    description: 'We’re spoiling one Grand Prize Winner with this luxurious prize package:',
    background: 'rgba(30, 37, 61, 0.8)',
    color: '#7E8FCD',
    card: [
      {
        index: '1',
        image: PackageIcon11,
        title: '1 Louis Vuitton Yoga Mat',
        price: '$2,390 value',
      },
      {
        index: '2',
        image: PackageIcon12,

        title: '1 Alo Gift Card',
        price: '$250 value',
      },
      {
        index: '3',
        image: PackageIcon13,

        title: '1 Lululemon Gift Card',
        price: '$250 value',
      },
      {
        index: '4',
        image: PackageIcon14,
        title: 'A one-year unlimited Yoga Joint membership, complete with mats, towels, and guest passes',
        price: '$2,268 value',
      },
      {
        index: '5',
        image: PackageIcon15,
        title: 'A huge bundle of premium Yoga Joint swag',
        price: '$100 value',
      },
      {
        index: '6',
        image: PackageIcon16,
        title: 'Unlimited on-demand classes in the Yoga Joint app',
        price: '$19 value',
      },
    ],
  },
  {
    index: 2,
    title: '3 Gold Prize Packages',
    price: '$2,887 VALUE',
    description: 'Three prize winners will receive our Gold Prize Package, which includes:',
    background: 'rgba(45, 39, 18, 0.8)',
    color: '#B7B199',
    card: [
      {
        index: '1',
        image: PackageIcon21,
        title: '1 Alo Gift Card',
        price: '$250 value',
      },
      {
        index: '2',
        image: PackageIcon22,
        title: '1 Lululemon Gift Card',
        price: '$250 value',
      },
      {
        index: '3',
        image: PackageIcon23,
        title: 'A one-year unlimited Yoga Joint membership, complete with mats, towels, and guest passes',
        price: '$2,268 value',
      },
      {
        index: '4',
        image: PackageIcon24,
        title: 'A huge bundle of premium Yoga Joint swag',
        price: '$100 value',
      },
      {
        index: '5',
        image: PackageIcon25,
        title: 'Unlimited on-demand classes in the Yoga Joint app',
        price: '$19 value',
      },
    ],
  },
  {
    index: 3,
    title: '6 Silver Prize Packages',
    price: '$2,387 VALUE',
    description: 'Six more winners will enjoy our fantastic Silver Prize Package, which includes:',
    background: 'rgba(43, 45, 50, 0.8)',
    color: '#9093A4',
    card: [
      {
        index: '1',
        image: PackageIcon31,
        title: 'A one-year unlimited Yoga Joint membership, complete with mats, towels, and guest passes',
        price: '$2,268 value',
      },
      {
        index: '2',
        image: PackageIcon32,
        title: 'A huge bundle of premium Yoga Joint swag',
        price: '$100 value',
      },
      {
        index: '3',
        image: PackageIcon33,
        title: 'Unlimited on-demand classes in the Yoga Joint app',
        price: '$19 value',
      },
    ],
  },
];

const PowerOfYjApp = [
  {
    id: 1,
    title: 'On-Demand Classes',
    description: 'Even before our Coral Springs studio unveils its magic, begin your sessions from the comfort of your space.',
    image: PlayScreenIcon,
    color: primaryTheme.colors.brand.amethyst,
  },
  {
    id: 2,
    title: 'Schedules & Reservations',
    description: 'Plan ahead and reserve your mat in our signature classes once we open!',
    image: ScheduleIcon,
    color: primaryTheme.colors.brand.mediumSlateBlue,
  },
  {
    id: 3,
    title: 'Stay Updated',
    description: 'Be the first to know about classes, events, and exclusive offers.',
    image: BellIcon,
    color: primaryTheme.colors.brand.violetPurple,
  },
];

const HowItWorksText = [
  {
    index: 1,
    text: `Share your unique\nreferral link.`,
    color: primaryTheme.colors.brand.amethyst,
    background: '#5A296C',
  },
  {
    index: 2,
    text: 'For each friend who signs up through your link, you get another entry into the giveaway!',
    color: primaryTheme.colors.brand.mediumSlateBlue,
    background: '#36397C',
  },
  {
    index: 3,
    text: 'Keep sharing for more\nchances to win.',
    color: primaryTheme.colors.brand.violetPurple,
    background: '#372D6B',
  },
];
const Stubs = {
  yogaBenefits1,
  yogaBenefits2,
  YogaJointMethod,
  YogaJointPeople,
  PreSalePackages,
  PowerOfYjApp,
  HowItWorksText,
};

export default Stubs;
