import { Box, chakra, Text, Image, Flex } from '@chakra-ui/react';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

const OuterContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: { base: '200px', lg: '212px' },
    boxSizing: 'border-box',
    alignItems: 'space-between',
    padding: { base: '25px 28px', lg: '37px 38px 20px' },
    borderRadius: '5px',
    width: { md: '70%', lg: 'unset' },
    margin: { md: 'auto', lg: 'unset' },
  },
});

const CustomText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '16px' },
    fontWeight: '400',
    lineHeight: { base: '23px', xl: '20px' },
    paddingTop: '10px',
    maxWidth: '316px',
  },
});

type SinglePackageProps = {
  image: string;
  title: string;
  price: string;
  background: string;
  color: string;
};

const SinglePackage = ({ title, price, image, background, color }: SinglePackageProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <OuterContainer
      opacity={enterCount >= 1 ? 1 : 0}
      ref={ref}
      animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
      position="relative"
      background={background}
    >
      <Image src={image} width="fit-content" />
      <Flex marginTop="auto" justifyContent="flex-end" flexDirection="column" color={color}>
        <CustomText>{title}</CustomText>
        <CustomText fontSize="20px">
          <strong> {price.split(' ')[0]} </strong>
          {price.split(' ').slice(1).join(' ')}
        </CustomText>
      </Flex>
    </OuterContainer>
  );
};

export default SinglePackage;
