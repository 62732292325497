import { Box, useMediaQuery } from '@chakra-ui/react';
import { Stub, useNavBarDimensions } from 'utils';
import { BackGroundTwo } from 'assets';
import { Packages } from 'components';

const PackagesSection = () => {
  const [isMobileView] = useMediaQuery('(max-width: 770px)');
  const { margin } = useNavBarDimensions();
  return (
    <Box
      paddingLeft={!isMobileView ? margin : '20px'}
      paddingRight={!isMobileView ? margin : '20px'}
      background={`rgba(0, 0, 0, 0.2) url(${BackGroundTwo})`}
      backgroundSize={{ base: '150% 10%', md: '100% 33%' }}
      backgroundRepeat="repeat-y !important"
    >
      {Stub.PreSalePackages.map((item) => (
        <Packages
          key={item.index}
          title={item.title}
          price={item.price}
          description={item.description}
          card={item.card}
          background={item.background}
          color={item.color}
        />
      ))}
    </Box>
  );
};

export default PackagesSection;
