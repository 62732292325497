import { Box, chakra, Text, useMediaQuery } from '@chakra-ui/react';
import { ShiftIconTwo } from 'assets';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { CountDown } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    backgroundRepeat: 'no-repeat !important',
    background: {
      base: '#10162d',
      lg: `
      linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)),
      url(${ShiftIconTwo})
    `,
    },
    backgroundPosition: 'top',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    background: { lg: 'radial-gradient(circle, rgba(59, 84, 177, 0.32), rgba(0, 0, 0, 0))' },
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
    marginTop: { base: '80px', md: '130px' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '29px', xl: '44px' },
    fontWeight: '600',
    lineHeight: { base: '36px', xl: '56px' },
    color: primaryTheme.colors.brand.white,
    textAlign: 'center',
    margin: { base: '5px 0 25px', xl: '5px 0 14px' },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '25px' },
    fontWeight: '500',
    lineHeight: { base: '23px', xl: '31px' },
    color: primaryTheme.colors.brand.white,
    textAlign: 'center',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '16px', xl: '18px' },
    fontWeight: '300',
    lineHeight: { base: '23px', xl: '26px' },
    color: primaryTheme.colors.brand.blueGrey,
    textAlign: 'center',
    maxWidth: '570px',
    margin: 'auto',
  },
});

const ComingNewSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 770px)');
  const { margin } = useNavBarDimensions();
  return (
    <OuterContainer
      opacity={enterCount >= 1 ? 1 : 0}
      ref={ref}
      paddingLeft={!isMobileView ? margin : '20px'}
      paddingRight={!isMobileView ? margin : '20px'}
    >
      <Container padding={{ base: '48px 0', md: '80px 0' }}>
        <SubTitle animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>Yoga Joint is coming to</SubTitle>
        <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>Coral Springs!</Title>
        <Description animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
          To celebrate, we’re giving away <strong>over $5,000 in fabulous prizes</strong> to ten lucky winners who enter before
          the deadline! Time is running out!
        </Description>
        <CountDown />
      </Container>
    </OuterContainer>
  );
};

export default ComingNewSection;
