import { Box, chakra, Divider } from '@chakra-ui/react';
import { SecondaryFooter, GetReadySection, PreSaleNextStepHero, ReadyToFlow } from 'components';
import { primaryTheme } from 'theming';

const TopBorder = chakra(Divider, {
  baseStyle: {
    width: '100%',
    height: '14px',
    backgroundImage: primaryTheme.colors.gradients.preSaleGradient,
    border: '1px solid transparent',
    boxSizing: 'border-box',
    opacity: 1,
  },
});

const ThankYouPage = () => {
  return (
    <Box>
      <TopBorder />
      <PreSaleNextStepHero />
      <ReadyToFlow />
      <GetReadySection
        title="Flow with us, wherever you go."
        description="Your yoga journey with Yoga Joint has just begun, and we're thrilled to practice FIIT and Flow alongside you every step of the way."
      />
      <SecondaryFooter />
    </Box>
  );
};

export default ThankYouPage;
