import { Box } from '@chakra-ui/react';
import { PreSaleRegisteredHero } from 'assets';
import { PreSaleHeroSection, SecondaryFooter, GetReadySection, HowItWorksSection, PromoBar } from 'components';
import { router } from 'navigation';
import { useEffect, useRef, useState } from 'react';
import { useRouteOptions } from 'react-typesafe-routes';

const ReferralPage = () => {
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToReferralLink = () => {
    formRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  const [isPromoVisible, setIsPromoVisible] = useState(true);

  useEffect(() => {
    let prevScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsPromoVisible(scrollTop < prevScrollPosition);
      prevScrollPosition = scrollTop;
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPromoVisible]);

  const { isAd } = useRouteOptions(router);
  console.log('ALOO ~ ReferralPage ~ isAd:', isAd);

  return (
    <Box>
      <PromoBar isPromoClosed={false} />
      <PreSaleHeroSection
        title="Multiply Your Chances To Win By Referring A Friend (or 12!)"
        subTitle="Referrals are <strong>limitless and FREE!</strong>!"
        highlightText="Grab Your Unique Referral Link Below!"
        description="Why journey alone when you can bring friends along for the ride!? Massively skyrocket your chances to win the <strong>Coral Springs Yoga Joint Grand Prize</strong> by letting more people know about this amazing Giveaway! It’s as easy as 1,2,3!"
        buttonText="GET REFERRAL LINK!"
        image={PreSaleRegisteredHero}
        isRegistered
        onClick={scrollToReferralLink}
      />
      <HowItWorksSection />
      <Box
        ref={formRef}
        marginTop={{ base: '80px', lg: '170px' }}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: 'url(https://yoga-joint-website.s3.amazonaws.com/assets/images/YJ_GIVEAWAY_UPVIRAL_LINK_BG.png)',
        }}
      >
        <iframe
          allowFullScreen
          className={isAd ? 'uvembed156002' : 'uvembed156452'}
          src="https://static.upviral.com/loader.html"
          title="UpViral iframe for the Referral Link"
        />
      </Box>
      <GetReadySection
        title={`Coral Springs,\nget ready to experience the Yoga Joint magic that has taken South Florida by storm!`}
        description=" <strong>Spread the word and fuel the buzz!</strong><br />
      Every share is a step closer to making Yoga Joint the go-to spot for everyone."
      />
      <SecondaryFooter />
    </Box>
  );
};

export default ReferralPage;
