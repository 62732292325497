import { Box, Button, chakra, Flex, Text } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { AnimationFrame } from 'utils';

const OuterContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: { base: '40px', lg: 'unset' },
    paddingBottom: { base: '40px', lg: 'unset' },
    width: { base: '100%', lg: '40%' },
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '40px',
    borderTopWidth: { base: '1px', lg: 'unset' },
    borderTopColor: { base: 'rgba(255, 255, 255, 0.35)', lg: 'unset' },
    borderTopStyle: { base: 'solid', lg: 'unset' },
  },
});

const LinkText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '21px', lg: '28px' },
    fontWeight: '500',
    lineHeight: { base: '28x', lg: '40px' },
    color: primaryTheme.colors.brand.lemonYellow,
    marginTop: '16px',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '13px', lg: '15px' },
    fontWeight: '700',
    lineHeight: { base: '18px', lg: '21px' },
    color: primaryTheme.colors.brand.white,
    textTransform: 'uppercase',
  },
});

type LinkSectionProps = {
  link: string;
};

const LinkSection = ({ link }: LinkSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <OuterContainer
      opacity={enterCount >= 1 ? 1 : 0}
      ref={ref}
      animation={inViewport || enterCount ? AnimationFrame.slideLeft : 'none'}
    >
      <Container>
        <Description>HERE IS YOUR UNIQUE REFERRAL LINK </Description>
        <LinkText> {link}</LinkText>
      </Container>

      <Button
        variant="primary"
        size="primary"
        width={{ base: '100%', md: '316px' }}
        height="56px"
        _focus={{ outline: '0 !important' }}
        fontWeight="700"
        fontSize="16px"
        margin={{ base: '39px auto 0', xl: '20px 0' }}
      >
        SHARE NOW!
      </Button>
    </OuterContainer>
  );
};

export default LinkSection;
