import { chakra, Flex } from '@chakra-ui/react';
import { YogaJointLogoWithTextSmall } from 'assets';
import { BottomComp } from 'components';
import { primaryTheme } from 'theming';

const Container = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: { base: '135px', lg: '175px' },
    background: '#08102B',
    color: primaryTheme.colors.brand.white,
    padding: { base: '20px 0 30px', lg: '51px 0 80px' },
    gap: { base: '20px', lg: '30px' },
  },
});

const SecondaryFooter = () => (
  <Container>
    <YogaJointLogoWithTextSmall color={primaryTheme.colors.brand.white} />
    <BottomComp isThreeForFreePage />
  </Container>
);

export default SecondaryFooter;
