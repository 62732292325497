import { Box, chakra, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo } from 'assets';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, Stub, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '160px' },
    background: `url(${BackGroundTwo})`,
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '80px', md: 'unset' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '30px', xl: '36px' },
    fontWeight: '500',
    lineHeight: { base: '40px', xl: '46px' },
    textAlign: 'center',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    marginBottom: { base: '35px', lg: '60px' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: '20px',
    fontWeight: '300',
    lineHeight: { base: '30px', xl: '34px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: { xl: '843px' },
    margin: 'auto',
    textAlign: 'center',
    marginTop: { base: '55px', lg: '100px' },
  },
});

const CustomText = chakra(Text, {
  baseStyle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: { base: '28px', xl: '26px' },
    textAlign: 'center',
    maxWidth: { lg: '300px' },
    whiteSpace: 'pre-line',
  },
});

const NumberText = chakra(Flex, {
  baseStyle: {
    fontSize: '20px',
    color: primaryTheme.colors.brand.white,
    fontWeight: '700',
    borderRadius: '50%',
    width: { base: '36px', lg: '47px' },
    height: { base: '36px', lg: '47px' },
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const InnerContainer = chakra(Flex, {
  baseStyle: {
    width: '90%',
    flexDirection: { base: 'column', lg: 'row' },
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: { base: '20px', lg: '0' },
  },
});

const CustomDivider = chakra(Box, {
  baseStyle: {
    height: { base: '58px', lg: '0' },
    borderLeft: { base: '2px solid' },
    borderTop: { lg: '2px solid' },
    width: { lg: '14rem', xl: '380px', '3xlB': '420px' },
    borderStyle: 'dotted !important',
    color: '#4B5271',
    alignSelf: 'center',
  },
});

const HowItWorksSection = () => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
      <Container
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
        paddingLeft={isMobileView ? margin : ''}
        paddingRight={isMobileView ? margin : ''}
      >
        <Title animation={inViewport || enterCount ? AnimationFrame.slideDown : 'none'}>How It Works!</Title>
        <InnerContainer>
          {Stub.HowItWorksText.map((item) => (
            <>
              <Flex key={item.index} animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <Box border={`2px solid ${item.background}`} padding="11px" borderRadius="50%">
                  <NumberText background={item.background}>{item.index}</NumberText>
                </Box>
              </Flex>
              {!isMobileView && item.index !== 3 && <CustomDivider />}

              {isMobileView && (
                <>
                  <CustomText
                    key={item.index}
                    color={item.color}
                    animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
                  >
                    {item.text}
                  </CustomText>
                  {item.index !== 3 && <CustomDivider />}
                </>
              )}
            </>
          ))}
        </InnerContainer>
        {!isMobileView && (
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            justifyContent="space-between"
            width="100%"
          >
            {Stub.HowItWorksText.map((item) => (
              <CustomText key={item.index} color={item.color}>
                {item.text}
              </CustomText>
            ))}
          </Flex>
        )}
        <Description animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
          Remember: <strong>Every referral amplifies your opportunity</strong> to snag those coveted prizes. Whether it’s one,
          ten, or a hundred – each gives you an extra chance to win BIG!{' '}
        </Description>
      </Container>
    </OuterContainer>
  );
};

export default HowItWorksSection;
