import { Button, chakra, Flex, Text } from '@chakra-ui/react';
import { primaryTheme } from 'theming';

const BottomContainer = chakra(Flex, {
  baseStyle: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: { base: '12px', md: '14px' },
    color: primaryTheme.colors.brand.slateGrey,
  },
});

type BottomCompProps = {
  isThreeForFreePage?: boolean;
};

const BottomComp = ({ isThreeForFreePage }: BottomCompProps) => (
  <BottomContainer>
    <Text marginRight="10px" width="max-content" fontWeight={500}>
      © {new Date().getFullYear()} Yoga Joint All Rights Reserved
    </Text>
    <Text>|</Text>
    <Button
      as="a"
      href={`${process.env.REACT_APP_URL}/privacy-policy`}
      target="_blank"
      fontWeight={500}
      marginLeft="8px"
      variant="footerButton"
      fontSize="14px"
      cursor="pointer"
      _focus={{ outline: '0 !important' }}
      _hover={{
        color: primaryTheme.colors.button.primary,
      }}
      color={isThreeForFreePage ? primaryTheme.colors.button.primary : 'unset'}
    >
      Privacy Policy
    </Button>
  </BottomContainer>
);

export default BottomComp;
