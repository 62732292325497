import { chakra, Flex, Text } from '@chakra-ui/react';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';

const OuterContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    gap: '15px',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    lineHeight: '130%',
    textAlign: 'center',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    whiteSpace: 'pre-line',
    margin: 'auto',
    maxWidth: { lg: '747px' },
    textTransform: 'capitalize',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    lineHeight: '140%',
    fontWeight: '500',
    letterSpacing: '-1px',
    color: primaryTheme.colors.brand.white,
    margin: 'auto',
    textAlign: 'center',
  },
});

type TextContentColumnProps = {
  title: string;
  description: string;
  isMainText?: boolean;
};

const TextContentColumn = ({ title, description, isMainText }: TextContentColumnProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer
      ref={ref}
      opacity={enterCount >= 1 ? 1 : 0}
      animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
    >
      <Title
        fontSize={{ base: isMainText ? '24px' : '32px', lg: isMainText ? '30px' : '36px' }}
        fontWeight={{ base: isMainText ? '600' : '500', lg: '500' }}
      >
        {title}
      </Title>
      <Description fontSize={{ base: isMainText ? '33px' : '20px', lg: isMainText ? '58px' : '24px' }}>{description}</Description>
    </OuterContainer>
  );
};

export default TextContentColumn;
