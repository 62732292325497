import React from 'react';
import { Button, ButtonProps, chakra, Flex, Text, VStack } from '@chakra-ui/react';

interface DownloadAppButtonProps extends ButtonProps {
  title: string;
  route?: string;
  subtitle: string;
  icon: React.ReactNode;
  isPreSalePage?: boolean;
}

const SubTitle = chakra(Text, {
  baseStyle: {
    margin: 0,
    fontSize: 11,
    fontWeight: 600,
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontWeight: 600,
    marginTop: '0px !important',
    fontSize: { base: '18px', md: '22px' },
  },
});

const DownloadAppButton = ({ icon, title, subtitle, route, isPreSalePage, ...props }: DownloadAppButtonProps) => {
  return (
    <Button
      as="a"
      href={route}
      target="_blank"
      textTransform="initial"
      height="56px"
      variant="quaternary"
      _focus={{ outline: '0 !important' }}
      cursor="pointer"
      transition="transform 0.4s"
      _hover={{
        transform: 'scale(1.04) !important',
      }}
      {...props}
    >
      <Flex alignItems="center">
        {icon}
        <VStack marginLeft="7px" alignItems="start">
          <SubTitle fontSize={isPreSalePage ? '9px' : '11px'}>{subtitle}</SubTitle>
          <Title fontSize={{ base: isPreSalePage ? '16px' : '18px', md: isPreSalePage ? '16px' : '22px' }}>{title}</Title>
        </VStack>
      </Flex>
    </Button>
  );
};

export default DownloadAppButton;
