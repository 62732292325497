import { Box, chakra, Divider, useMediaQuery } from '@chakra-ui/react';
import { PreSaleHero, YogaJointLogoWithTextSmall } from 'assets';
import { PreSaleHeroSection, ComingNewSection, PackagesSection, FormSection, WhatIsYogaJoint, SecondaryFooter } from 'components';
import { useRef } from 'react';
import { primaryTheme } from 'theming';

const TopBorder = chakra(Divider, {
  baseStyle: {
    width: '100%',
    height: '14px',
    backgroundImage: primaryTheme.colors.gradients.preSaleGradient,
    border: '1px solid transparent',
    boxSizing: 'border-box',
    opacity: 1,
  },
});
const HomePage = () => {
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    formRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  return (
    <Box>
      <TopBorder />
      {!isMobileView && (
        <Box margin="58px 0 0 80px">
          <YogaJointLogoWithTextSmall />
        </Box>
      )}
      <PreSaleHeroSection
        title={
          isMobileView
            ? `Enter to Win a\n$2,390 Louis Vuitton<small>®</small>\nYoga Mat FREE\n+ $2,887 in Prizes!`
            : `Enter to Win\na $2,390 Louis Vuitton<small>®</small>\nYoga Mat FREE\n+ $2,887 in Prizes!`
        }
        subTitle="As We Celebrate The Grand Opening Of Yoga Joint <strong>Coral Springs</strong>!"
        bigText="10 WINNERS WILL BE CHOSEN!"
        description="Type in your email address below and click the button to enter our epic grand opening celebration giveaway! Plus
        increase your chances of winning by referring your friends on the next page!"
        buttonText={isMobileView ? 'ENTER FREE GIVEAWAY!' : 'ENTER FREE GIVEAWAY NOW!'}
        image={PreSaleHero}
        onClick={scrollToForm}
      />
      <ComingNewSection />
      <PackagesSection />
      <Box ref={formRef}>
        <FormSection
          title={`Enter to win a \n $2,390 Louis Vuitton<small>®</small> Yoga Mat FREE + $2,887 in Prizes!`}
          description="Type in your email address below and click the button to enter our epic Grand Opening Celebration Giveaway! Plus, increase your chances of winning by referring your friends on the next page!"
        />
      </Box>
      <WhatIsYogaJoint onClick={scrollToForm} />
      <SecondaryFooter />
    </Box>
  );
};

export default HomePage;
