import { chakra, Flex, Text, Divider } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { useState, useEffect } from 'react';
import React from 'react';

const Container = chakra(Flex, {
  baseStyle: {
    width: { base: '95%', md: '50%' },
    margin: { base: '30px auto 0', md: '40px auto 0' },
    justifyContent: 'space-between',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '33px', xl: '50px' },
    fontWeight: '400',
    lineHeight: { base: '42px', xl: '63px' },
    color: primaryTheme.colors.brand.blueGrey,
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '10px', xl: '14px' },
    fontWeight: '400',
    lineHeight: { base: '11px', xl: '18px' },
    color: primaryTheme.colors.brand.blueGrey,
    textAlign: 'center',
    letterSpacing: '0.25em',
    textTransform: 'uppercase',
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    width: { base: '100px', md: '72px' },
    border: '0.5px solid',
    transform: 'rotate(-90deg)',
    color: primaryTheme.colors.brand.blueGrey,
    alignSelf: 'center',
    opacity: 0.7,
  },
});

const CountDown = () => {
  const calculateTimeRemaining = () => {
    const targetDate = new Date(process.env.REACT_APP_OPENING_DATE || '04-01-2024');
    targetDate.setHours(0, 0, 0, 0);
    const targetTime = targetDate.getTime();
    const currentTime = new Date().getTime();
    const timeDiff = targetTime - currentTime;
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
      days: days >= 0 ? days.toString().padStart(2, '0') : '00',
      hrs: hours >= 0 ? hours.toString().padStart(2, '0') : '00',
      mins: minutes >= 0 ? minutes.toString().padStart(2, '0') : '00',
      secs: seconds >= 0 ? seconds.toString().padStart(2, '0') : '00',
    };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <Container>
      {Object.entries(timeRemaining).map(([key, value]) => (
        <React.Fragment key={key}>
          <Flex width="8em" key={key} flexDirection="column" justifyContent="center">
            <Title alignSelf="center">{value}</Title>
            <Description>{key}</Description>
          </Flex>
          {key !== 'secs' && <CustomDivider />}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default CountDown;
