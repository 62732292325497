import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Form, Formik } from 'formik';
import { PrimaryInput, RequestButton } from 'components';
import { EnterFreeGiveAway } from 'utils/yupSchemas';
import { useRouteOptions } from 'react-typesafe-routes';
import { router } from 'navigation';

const Disclaimer = chakra(Text, {
  baseStyle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    color: primaryTheme.colors.brand.white,
    marginTop: '24px',
    opacity: '65%',
  },
});

const FieldContainer = chakra(Flex, {
  baseStyle: {
    padding: { base: '28px 0', xl: '62px 0' },
    flexDirection: 'column',
    width: { base: '100%', xl: '80%' },
  },
});

const FieldRow = chakra(Flex, {
  baseStyle: {
    flexDirection: { base: 'column', lg: 'row' },
    gap: { lg: '78px' },
    width: '100%',
  },
});

const FormFields = () => {
  const formEl = useRef<HTMLFormElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const { inViewport, enterCount } = useInViewport(formRef);
  const { isAd } = useRouteOptions(router);
  console.log("ALOO ~ FormFields ~ isAd:", isAd)

  return (
    <Box opacity={enterCount >= 1 ? 1 : 0} ref={formRef}>
      <Formik
        validationSchema={EnterFreeGiveAway}
        initialValues={EnterFreeGiveAway.getDefault()}
        onSubmit={() => formEl.current?.submit()}
      >
        {({ submitForm }) => (
          <Form
            ref={formEl}
            method="post"
            onSubmit={submitForm}
            name={isAd ? 'upviralForm156002' : 'upviralForm156452'}
            action={
              isAd
                ? 'https://app.upviral.com/site/parse_new_users/call/ajax/campId/156002/snippet_id/65cf694dd8c9d'
                : 'https://app.upviral.com/site/parse_new_users/call/ajax/campId/156452/snippet_id/65e0a99a5a08b'
            }
          >
            <FieldContainer opacity={enterCount >= 1 ? 1 : 0} ref={formRef}>
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <PrimaryInput name="FirstName" label="First Name *" type="text" placeholder="First Name" required />
              </FieldRow>
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <PrimaryInput name="LastName" label="Last Name *" type="text" placeholder="Last Name" required />
              </FieldRow>
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <PrimaryInput name="email" label="Email *" type="email" placeholder="Email Address" required />
              </FieldRow>
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <PrimaryInput name="Phone" label="Telephone *" type="number" placeholder="Telephone Number" isMasked required />
              </FieldRow>
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <RequestButton
                  width="100%"
                  title="ENTER FREE GIVEAWAY NOW!"
                  type="submit"
                  marginTop="20px"
                  name="upviralsubmit"
                  value="Submit"
                />
                <input type="hidden" name="reflink" value="" />
              </FieldRow>
              <FieldRow animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
                <Disclaimer>
                  By entering, you agree to receive emails from Yoga Joint about news, updates, offers, and more. We respect your
                  privacy. Unsubscribe at any time.
                </Disclaimer>
              </FieldRow>
            </FieldContainer>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FormFields;
