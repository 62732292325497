import { Box, Button, chakra, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { AppleIcon, BackgroundSeven, PlayStoreIcon } from 'assets';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { DownloadAppButton } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: `rgba(0, 0, 0, 0.2) url(${BackgroundSeven})`,
    backgroundPosition: 'right, right',
    backgroundSize: { base: 'cover', md: 'cover' },
    backgroundRepeat: 'no-repeat !important',
    marginBottom: '40px',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
    margin: { base: '80px 0 0 ', md: '221px auto 0' },
    maxWidth: { xl: '1254px' },
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '37px', lg: '58px' },
    fontWeight: '400',
    lineHeight: { base: '44px', lg: '70px' },
    color: primaryTheme.colors.brand.white,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    backgroundClip: 'text',
    fontSize: { base: '19px', lg: '26px' },
    fontWeight: '500',
    lineHeight: { base: '28px', lg: '39px' },
    textAlign: 'center',
    paddingTop: { base: '17px', lg: '12px' },
  },
});

const ReadyToFlow = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  const { margin } = useNavBarDimensions();
  return (
    <OuterContainer paddingLeft={!isMobileView ? margin : '20px'} paddingRight={!isMobileView ? margin : '20px'}>
      <Container opacity={enterCount >= 1 ? 1 : 0} ref={ref} padding={{ base: '45px 0 0', md: '50px 0 78px' }}>
        <Title animation={inViewport || enterCount ? AnimationFrame.slideRight : 'none'}>Ready to Flow from the Get-Go?</Title>

        <Description animation={inViewport || enterCount ? AnimationFrame.slideLeft : 'none'}>
          Download our app and start your Yoga Joint adventure now!
        </Description>
        <Button
          variant="primary"
          size="primary"
          width={{ base: '315px', md: '381px' }}
          height="56px"
          _focus={{ outline: '0 !important' }}
          animation={inViewport || enterCount ? AnimationFrame.slideDown : 'none'}
          margin={{ base: '39px auto 41px', xl: '40px 0' }}
          fontWeight="700"
          letterSpacing="-0.5px"
          fontSize={{ base: '14px', lg: '16px' }}
        >
          Download the Yoga Joint App
        </Button>
        <Flex animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
          <DownloadAppButton
            route="https://apps.apple.com/us/app/shift-by-yoga-joint/id1553410096"
            icon={<AppleIcon />}
            title="App Store"
            width={{ base: '147px', md: '157px' }}
            subtitle="Download on the"
            marginRight={{ base: 0, sm: '20px' }}
            marginBottom={{ base: '53px', md: 0 }}
            isPreSalePage
          />
          <DownloadAppButton
            route="https://play.google.com/store/apps/details?id=com.yogajoint.mobile"
            width={{ base: '147px', md: '157px' }}
            icon={<PlayStoreIcon />}
            title="Google Play"
            subtitle="GET IT ON"
            isPreSalePage
          />
        </Flex>
      </Container>
    </OuterContainer>
  );
};

export default ReadyToFlow;
