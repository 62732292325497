import { ComponentStyleConfig } from '@chakra-ui/react';

const TextStyle: ComponentStyleConfig = {
  variants: {
    primary: {
      color: '#132a4d',
    },
  },
};

export default TextStyle;
