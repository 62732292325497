import './App.css';
import { primaryTheme } from './theming';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterSwitch } from 'react-typesafe-routes';
import { PrimaryLayout } from './components';
import { Font } from './assets';
import { router } from 'navigation';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Font />
      <ChakraProvider theme={primaryTheme}>
        <PrimaryLayout>
          <RouterSwitch router={router} />
        </PrimaryLayout>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default App;
